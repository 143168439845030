<template>
  <section id="analytics" class="section_container">
    <v-container>
      <v-row justify="space-around">
        <v-col class="d-flex col-md-6 col-sm-12">
          <v-card :color="cardColor" elevation="24" class="flex-grow-1">
            <v-card-text>
              <p class="pl-5" style="color: white">
                {{ text }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="d-flex col-md-6 col-sm-12">
          <v-card :color="cardColor" elevation="24" class="flex-grow-1">
            <v-card-text>
              <p class="pl-5" style="color: white">
                {{ text }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<style lang="scss" scoped>
.section_container {
  width: 70%;
  margin: 0 auto;
  background-color: #fff;
}
.section_link {
  color: white;
  text-decoration: none;
}
.section_link:hover {
  color: black;
}
</style>
<script>
export default {
  name: "HomeSectionAnalytics",
  data() {
    return {
      // cardColor: "#5074b5",
      cardColor: "black",
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel
              ante eu lectus aliquam bibendum ornare ac nisl. Duis velit eros,
              consequat quis pharetra sed, rhoncus a ipsum. Mauris in sapien
              finibus, bibendum lacus nec, maximus arcu. Donec augue eros,
              varius eget fringilla ac, placerat vitae orci. Fusce rhoncus
              lectus ac sapien rhoncus, vel sollicitudin magna ultricies. Cras
              mi enim, venenatis at lectus eu, tincidunt ultricies sem.`,
    };
  },
};
</script>